"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
// @ts-nocheck
const react_native_1 = require("react-native");
const react_native_paper_1 = require("react-native-paper");
const react_1 = require("react");
const Helper_1 = require("../Helper");
const Theme_1 = require("../Theme");
const DropDown = (0, react_1.forwardRef)((props, ref) => {
    const activeTheme = (0, react_native_paper_1.useTheme)();
    const { disabled = false, error = false, multiSelect = false, value, setValue, activeColor, mode, label, showLabelOnDropDownOnly, placeholder, inputProps, list, dropDownContainerMaxHeight, dropDownContainerHeight, dropDownStyle, dropDownItemStyle, dropDownItemSelectedStyle, dropDownItemTextStyle, dropDownItemSelectedTextStyle, titleNumberOfLines = 1 } = props;
    const [visible, setVisible] = (0, react_1.useState)(false);
    const [displayValue, setDisplayValue] = (0, react_1.useState)("");
    const [inputLayout, setInputLayout] = (0, react_1.useState)({
        height: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    const onDismiss = () => {
        setVisible(false);
    };
    const showDropDown = () => {
        setVisible(true);
    };
    const onLayout = (event) => {
        setInputLayout(event.nativeEvent.layout);
    };
    (0, react_1.useEffect)(() => {
        if (multiSelect) {
            const _labels = list
                .filter((_) => value.indexOf(_.value) !== -1)
                .map((_) => _.label)
                .join(", ");
            setDisplayValue(_labels);
        }
        else {
            const _label = list.find((_) => _.value === value)?.label;
            if (_label) {
                if (!value) {
                    setDisplayValue('');
                }
                else {
                    setDisplayValue(_label);
                }
            }
        }
    }, [list, value]);
    const isActive = (0, react_1.useCallback)((currentValue) => {
        if (multiSelect) {
            return value.indexOf(currentValue) !== -1;
        }
        else {
            return value === currentValue;
        }
    }, [value]);
    const setActive = (currentValue) => {
        if (multiSelect) {
            const valueIndex = value.indexOf(currentValue);
            const values = value.split(",");
            if (valueIndex === -1) {
                setValue([...values, currentValue].join(","));
            }
            else {
                setValue([...values].filter((value) => value !== currentValue).join(","));
            }
        }
        else {
            setValue(currentValue);
        }
    };
    return ((0, jsx_runtime_1.jsx)(react_native_paper_1.Menu, { visible: visible, onDismiss: onDismiss, anchor: (0, jsx_runtime_1.jsx)(react_native_paper_1.TouchableRipple, { disabled: disabled, onPress: showDropDown, onLayout: onLayout, "aria-label": props["aria-label"] ?? label + ': ' + displayValue, children: (0, jsx_runtime_1.jsx)(react_native_1.View, { style: { pointerEvents: "none" }, children: (0, jsx_runtime_1.jsx)(react_native_paper_1.TextInput, { accessible: false, disabled: disabled, error: error, readOnly: true, value: displayValue, mode: mode, label: showLabelOnDropDownOnly ? undefined : label, dense: true, placeholder: placeholder, right: (0, jsx_runtime_1.jsx)(react_native_paper_1.TextInput.Icon, { disabled: true, style: { marginBottom: Helper_1.Helper.isPlatformWeb ? 8 : 4 }, icon: visible ? 'chevron-up' : 'chevron-down', theme: {
                            ...Theme_1.THEME,
                            colors: { onSurfaceDisabled: disabled ? Theme_1.THEME.TextDisabled : Theme_1.THEME.Text }
                        } }), ...inputProps, style: [Theme_1.STYLES.input, { pointerEvents: "none" }] }) }) }), style: {
            width: inputLayout?.width,
            marginTop: inputLayout?.height,
            ...dropDownStyle,
        }, children: (0, jsx_runtime_1.jsx)(react_native_1.ScrollView, { bounces: false, style: {
                ...(dropDownContainerHeight
                    ? {
                        height: dropDownContainerHeight,
                    }
                    : {
                        maxHeight: dropDownContainerMaxHeight || 350,
                    }),
            }, children: list.map((_item, _index) => ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: (0, jsx_runtime_1.jsx)(react_native_paper_1.TouchableRipple, { disabled: _item.disabled, style: {
                        flexDirection: "row",
                        alignItems: "center",
                    }, onPress: () => {
                        setActive(_item.value);
                        onDismiss();
                    }, children: (0, jsx_runtime_1.jsxs)(react_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_native_paper_1.List.Item, { titleNumberOfLines: titleNumberOfLines, titleStyle: {
                                    fontWeight: isActive(_item.value) ? "500" : "400",
                                    color: isActive(_item.value)
                                        ? activeColor || activeTheme.colors.primary
                                        : (_item.disabled ? Theme_1.THEME.TextLight : Theme_1.THEME.Text),
                                    ...(isActive(_item.value)
                                        ? dropDownItemSelectedTextStyle
                                        : dropDownItemTextStyle)
                                }, title: _item.custom || _item.label, style: {
                                    flex: 1,
                                    ...(isActive(_item.value) ? { backgroundColor: `${Theme_1.THEME.Primary}22` } : {}),
                                    ...(isActive(_item.value)
                                        ? dropDownItemSelectedStyle
                                        : dropDownItemStyle),
                                } }), multiSelect && ((0, jsx_runtime_1.jsx)(react_native_paper_1.Checkbox.Android, { theme: {
                                    colors: { accent: activeTheme?.colors.primary },
                                }, status: isActive(_item.value) ? "checked" : "unchecked", onPress: () => setActive(_item.value) }))] }) }) }, _item.value))) }) }));
});
exports.default = DropDown;
